export const DEFAULT_DATETIME_FORMAT = 'dd/MM/yyyy';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const GET_ORDERS = '/api/order-transparency-service/v3';

export const GET_INVOICE = '/api/v1/invoices-service/v2';

export const LONG_LOCALIZED_DATE_AND_TIME = 'PPPp';

export const LOCALIZED_DATE = 'PPP';

export const APP_TRACE_ID = 'BeesCare-l2';

export const ACCOUNT_ENDPOINT = '/api/bees-care-hub-bff/account/';

export const GET_TICKETS = '/api/v1/tickets/user-group-context';

export const GET_DYNAMIC_FORMS_ENDPOINT = '/api/care-dynamic-forms/ticket/';

export const PUT_DYNAMIC_FORMS_ENDPOINT = '/api/v1/ticket-relay/';

export const GET_USER_GROUPS = '/api/bees-care-hub-bff/groups';

// Toggles
export const COMPANY_TOGGLE_UAT: { [key: string]: string } = {
  '3c5eece4-ac37-44e3-a95e-a264802a7dfe': 'brf_',
  '2d741ecd-9e84-4107-aaed-a6f189528a07': 'mercasid_',
  'c069c91e-ab82-4fdd-83ab-057bb1b11993': 'gloria_',
  '70b33632-6bce-4eeb-95a2-ba0be90bf908': 'nestle_', // EC
  'd8e9fbc3-0678-4778-beb7-74165906d3fa': 'nestle_', // BR
  'dd6e82ad-c47d-4688-8a4c-76137c90f7d2': 'gpa_',
};

export const COMPANY_TOGGLE_PROD: { [key: string]: string } = {
  'c4c09157-d045-4271-8c18-d8b99187fd00': 'brf_',
  'c153705d-0929-4c95-bcc7-23338e90d3c9': 'mercasid_',
  '8445c121-3697-4f24-9128-9baf81ab0292': 'gloria_',
  'dec2a3ed-926c-4441-8abb-aa1f6b433b47': 'nestle_', // EC
  '95ea158a-6a14-4f56-ba78-484c3a467e84': 'nestle_', // BR
  'fca0073f-93cf-4170-a088-9b311fc9f1e0': 'gpa_',
};
